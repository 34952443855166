const projects = [
  {
    id: 1,
    title: "JS Questions",
    tags: ["React", "Tailwind CSS", "Vite"],
    body: "A quiz web app using questions from Lydia Hallie's Advanced JS questions repo.",
    img: "jsquestions",
    vercel: "https://js-questions-g1aexp249-orreryofcode.vercel.app/",
    github: "https://github.com/orreryofcode/JS-Questions",
    count: "01",
  },
  {
    id: 2,
    title: "PlayStation Store Rebuild",
    tags: ["React", "Vite", "JavaScript", "CSS"],
    body: "A rebuild of the PlayStation Store page layout. Assets were taken directly from the page at the time of creation. This was a project where I wanted to practice my ability to create something from an already existing design.",
    img: "psstore",
    vercel: "https://ps-store-landing-page.vercel.app/",
    github: "https://github.com/orreryofcode/PS_store-landing-page",
    count: "02",
  },
  {
    id: 3,
    title: "Dota 2 Card Generator",
    tags: ["React", "API"],
    body: "A web app that takes a user's Steam Friend ID and generates a card with various user stats from their Dota 2 account. Uses the OpenDota API.",
    img: "dota2card",
    vercel: "https://p47936.csb.app/",
    github: "https://codesandbox.io/s/dota2-card-generator-p47936",
    count: "03",
  },
  {
    id: 4,
    title: "Mapty - JS Course Project",
    tags: ["JavaScript", "CSS", "OOP", "Leaflet"],
    body: "A web app completed in order to practice and learn more about OOP design principles. Taken from Jonas Schmedtmann's JavaScript course on Udemy",
    img: "mapty",
    vercel: "https://orreryofcode.github.io/mapty_oop/",
    github: "https://github.com/orreryofcode/mapty_oop",
    count: "04",
  },
  {
    id: 5,
    title: "Triage",
    tags: ["React", "Redux Toolkit", "MongoDB"],
    body: "A ticketing app that allows users to submit tickets in the event they are having issues with some piece of software. Each user is only able to see their own submitted tickets. Any changes to the status of tickets or deletion tickets would be done on the backend.",
    img: "triage",
    vercel: "https://mern-tickets-app.vercel.app/login",
    github: "https://github.com/orreryofcode/mern-tickets-app",
    count: "05",
  },
  {
    id: 6,
    title: "Iron Fist Companion",
    tags: ["React", "JS", "CSS"],
    body: "A web app for the game Tekken 7 that provides users with various character information such as frame data and optimized combos.",
    img: "ifc",
    vercel: "https://tekken-ifcv2.vercel.app/",
    github: "https://github.com/orreryofcode/tekken_IFCv2",
    count: "06",
  },
  // {
  //   id: 5,
  //   title: "Travlr",
  //   tags: ["CSS", "React", "MongoDB"],
  //   body: "An app that lets the user choose from multiple options and then recommends a travel destination based on the selected choices. Each destination has associated tags and these are used to make the final selection. Built with Next.JS to get a basic understanding of the framework.",
  //   img: "travlr",
  //   vercel: "https://flightr-nnyam0ccp-orreryofcode.vercel.app/",
  //   github: "https://github.com/orreryofcode/flightr",
  //   count: "05",
  // },
  {
    id: 7,
    title: "HouseMarket",
    tags: ["HTML", "CSS", "React", "Firebase"],
    body: "An app that lets the user create an account and add listings for properties for rent or sale. This was designed with mobile in mind.",
    img: "housemarket",
    vercel: "https://housing-market-proj.vercel.app/",
    github: "https://github.com/orreryofcode/housing-market-proj",
    count: "07",
  },
];

export default projects;
